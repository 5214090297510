import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';
import AddToCart from '../AddToCart';


export default function BookPage() {

  document.title = `The Lie of the Truth by René Daumal | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `In this brief, dense essay, Daumal bids us to resist the very notion of the truth, and to recognize it as an artistic and metaphysical dead - end.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../daumal.png' /></div>
      <div className='n-image n-image-spine'><img src='../../daumalspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../SeriesOneBack.png' /></div>
      <div className='n-image n-image-2'><img src='../../daumal3.png' /></div>
      <div className='n-image n-image-3'><img src='../../daumal2.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/one'>Series One</Link> | Autumn 2023</div>
      <h1 className='n2-book-title'>The Lie of the Truth</h1>
      <div className='n2-book-author'>René Daumal</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-5-0</div>
        <div style={{ marginBottom: '6px' }}>60pp | $12.99 USD</div>
        <div className='buyingOptions'>
          <AddToCart context={'product'} id={'9798989378050'} />
        </div>

      </div>
      <div className='mainText'>
        <p><em>"At the beginning, there was error. Truth is one, but error proliferates. Man tracks it down & cuts it up into little pieces hoping to turn it into grains of truth. But the ultimate atom will always essentially be an error, a miscalculation."</em></p>
        <p>In this brief, dense essay, Daumal bids us to resist the very notion of the truth, and to recognize it as an artistic and metaphysical dead-end.</p>
        <p><strong>René Daumal</strong> (1908-1944) was a French poet and writer often associated with surrealism (though he fought against the label), spiritualism, and ‘pataphysics.’ He is perhaps best remembered for the posthumously published novel, <em>Mount Analogue</em> (1952).</p>
      </div>
      <div style={{ marginTop: '12px' }}>
        <h4>More Buying Options</h4>
        <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/the-lie-of-the-truth-rene-daumal'>Buy from Asterism (US/Canada)</a><br />
        <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/rene-daumal-the-lie-of-the-truth-1'>Buy from PKB (UK)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798989378005-rene-daumal-the-lie-of-the-truth'>Buy from Idea Books (Europe/Asia)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/forthcoming-rene-daumal-the-lie-of-truth'>Buy from Perimeter (Australia/Oceania)</a>
      </div>
    </div>
  </div>)
}