import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import CartIcon from '../../icons/cart';
import { makeRequest, validateEmail, updateCartState } from '../../utils';
import { useRecoilState } from 'recoil';
import { cartState } from '../../atoms';
import AddToCart from '../AddToCart';
import './style.css';

export default function AboutPage() {
  const [cart, setCart] = useRecoilState(cartState);

  document.title = `All Titles | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `All titles from Series One and Series Two`);

  const addToCart = (item) => {
    let newCart = updateCartState(cart, item);
    setCart(newCart);
  }

  return (
    <div className='pageContainer booksLanding'>

      <div className='booksPage'>
        <p>Hanuman Editions is a publishing project reimagining the legacy of Hanuman Books, the legendary and cult series of chapbooks that were printed in southern India and published out of the storied Chelsea Hotel in New York City between 1986 and 1993. Our books are designed as a contemporary homage to the playful kitsch and small, object-like format of the original series, evoking the Hanuman Chalisa, a folk compendium of chants to the Hindu god Hanuman, sold in the bazaars of India. As Hanuman Editions, we seek to gather a trans-geographical, trans-disciplinary poetics through commissioned writings in the spirit of avant-garde foundations. <Link to='/about'>Read more on the series here</Link>.</p>

        <h2 className='gold'>Series Two</h2>

        <h3 className='seriesSubhead'>Individual Titles</h3>

        <div className='new-grid'>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/life-story-mckenzie-wark'>
                  <img src='../../LifeStory.png' />
                </Link>
              </div>
              {/*<div className='new-product-controls npc-card'>
                <div className='nb-info'>
                  <div className='nb-title'>Life Story</div>
                  <div className='nb-author'>McKenzie Wark</div>
                </div>
                <div>
                  <AddToCart context={'landing'} id={'9798989378067'} />
                </div>
                <div className='npc-learn'>
                  Learn more...
                </div>
              </div>*/}
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Life Story</div>
                <div className='nb-author'>McKenzie Wark</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378067'} />
              </div>
            </div>
          </div>

          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/grocery-list-bora-chung'>
                  <img src='../../GroceryList.png' />
                </Link>
              </div>
              {/*<div className='new-product-controls npc-card'>
                <div className='nb-info'>
                  <div className='nb-title'>Grocery List</div>
                  <div className='nb-author'>Bora Chung</div>
                </div>
                <div>
                  <AddToCart context={'landing'} id={'9798990416529'} price={'price_1PTBM1FoScMjUSrmDaiSQ6l4'} />
                </div>
                <div className='npc-learn'>
                  Learn more...
                </div>
              </div>*/}

            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Grocery List</div>
                <div className='nb-author'>Bora Chung</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798990416529'} />
              </div>
            </div>

          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/frontier-index-raymond-pettibon-mike-top'>
                  <img src='../../Frontier.png' />
                </Link>
              </div>
              {/*<div className='new-product-controls npc-card'>
                <div className='nb-info'>
                  <div className='nb-title'>The Frontier Index</div>
                  <div className='nb-author'>Raymond Pettibon & Mike Topp</div>
                </div>
                <div>
                  <AddToCart context={'landing'} id={'9798990416512'} />
                </div>
                <div className='npc-learn'>
                  Learn more...
                </div>
              </div>*/}
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Frontier Index</div>
                <div className='nb-author'>Raymond Pettibon & Mike Topp</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798990416512'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/insistence-as-a-fine-art-enrique-vila-matas'>
                  <img src='../../Insistence.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Insistence as a Fine Art</div>
                <div className='nb-author'>Enrique Vila-Matas</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798990416505'} />
              </div>
            </div>
          </div>


          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/kuruntokai-vivek-narayanan'>
                  <img src='../../Kuruntokai.png' />
                </Link>
              </div>


            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Kuruntokai and Its Mirror</div>
                <div className='nb-author'>Vivek Narayanan</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378098'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/fan-mail-frank-letters-crank-calls-cookie-mueller'>
                  <img src='../../FanMail.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Fan Mail, Frank Letters and Crank Calls</div>
                <div className='nb-author'>Cookie Mueller</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378081'} />
              </div>
            </div>
          </div>
        </div>

        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '64px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series Two box sets produced in a hand-numbered edition of 500.</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series2_fm.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Flamingo</div>

              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_fl'} text={'Add to Cart'} />
              </div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_nb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>New Blue</div>

              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_nb'} text={'Add to Cart'} />
              </div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_tb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Tabriz Blue</div>

              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_tb'} text={'Add to Cart'} />
              </div>
            </div>
          </div>


          <div className='boxGrid-item'>
            <img src='../../series2_ct.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Citrine</div>
              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_ct'} text={'Add to Cart'} />
              </div>

            </div>
          </div>
        </div>

        <h2 className='gold'>Series One</h2>

        <h3 className='seriesSubhead'>Individual Titles</h3>


        <div className='new-grid'>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/on-my-painting-max-beckmann'>
                  <img src='../../beckmann2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>On My Painting</div>
                <div className='nb-author'>Max Beckmann</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378005'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/the-ice-storm-john-ashbery'>
                  <img src='../../ashbery2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Ice Storm</div>
                <div className='nb-author'>John Ashbery</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378012'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/feminine-hijinx-dodie-bellamy'>
                  <img src='../../bellamy2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Feminine Hijinx</div>
                <div className='nb-author'>Dodie Bellamy</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378029'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/garden-of-ashes-cookie-mueller'>
                  <img src='../../mueller.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Garden of Ashes</div>
                <div className='nb-author'>Cookie Mueller</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378036'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/bread-and-water-eileen-myles'>
                  <img src='../../myles.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Bread and Water</div>
                <div className='nb-author'>Eileen Myles</div>
              </div>
              <div>
                <AddToCart context='landing' id={'9798989378043'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/the-lie-of-the-truth-rene-daumal'>
                  <img src='../../daumal.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Lie of the Truth</div>
                <div className='nb-author'>René Daumal</div>
              </div>
              <div>
                <AddToCart context='landing' id={'9798989378050'} />
              </div>
            </div>
          </div>
        </div>

        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '96px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series One box sets produced in a hand-numbered edition of 250.*</p>
        <p className='finePrint'>* Sold out</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series1_em.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Emerald</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>
          <div className='boxGrid-item'>
            <img src='../../series1_tq.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Turquoise</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>
          <div className='boxGrid-item'>
            <img src='../../series1_cp.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Candy Pink</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series1_az.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Azure Blue</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>
        </div>


      </div>
    </div >
  )
}