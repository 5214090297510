import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';
import AddToCart from '../AddToCart';


export default function BookPage() {

  document.title = `The Ice Storm by John Ashbery | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Published in 1987 as a standalone volume by Hanuman Books, John Ashbery's The Ice Storm is an extended prose-poem by one of the most important and influential poets of our time.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../ashbery2.png' /></div>
      <div className='n-image n-image-spine'><img src='../../ashberyspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../SeriesOneBack.png' /></div>
      <div className='n-image n-image-2'><img src='../../ashbery3.png' /></div>
      <div className='n-image n-image-3'><img src='../../ashbery4.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/one'>Series One</Link> | Autumn 2023</div>
      <h1 className='n2-book-title'>The Ice Storm</h1>
      <div className='n2-book-author'>John Ashbery</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-1-2</div>
        <div style={{ marginBottom: '6px' }}>60pp | $12.99 USD</div>
        <div className='buyingOptions'>
          <AddToCart context={'product'} id={'9798989378012'} />
        </div>
      </div>
      <div className='mainText'>

        <p><em>"…isn’t really a storm of course because unlike most storms it isn’t one till it’s over and people go outside and say will you look at that. And by then it’s of course starting to collapse. Diamond rubble, all galled glitter, heaps of this and that in corners and beside posts where the draft has left them—are you sure it’s this you were waiting for while the storm—the real one—pressed it all into the earth to emphasize a point that melts away as fast as another idea enters the chain of them in the conversation about earth and sky and woods and how you should be good to your parents and not cheat at cards."</em></p>
        <p>Published in 1987 as a standalone volume by Hanuman Books, John Ashbery's <em>The Ice Storm</em> is an extended prose-poem by one of the most important and influential poets of our time.</p>
        <p><strong>John Ashbery</strong> (1927-2017) was an American poet and critic. Referred to often as an "avant-garde" poet, "experimentalist", or "surrealist", Ashbery's work resisted easy classification. He was awarded nearly every major prize for an American poet during his career, including a Pulitzer Prize in 1967 for <em>Self-Portrait in a Convex Mirror</em>.</p>

      </div>

      <div style={{ marginTop: '12px' }}>
        <h4>More Buying Options</h4>
        <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/the-ice-storm-john-ashbery'>Buy from Asterism (US/Canada)</a><br />
        <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/john-ashbery-the-ice-storm-old-copy'>Buy from PKB (UK)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798989378012-john-ashbery-the-ice-storm'>Buy from Idea Books (Europe/Asia)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/forthcoming-john-ashbery-the-ice-storm'>Buy from Perimeter (Australia/Oceania)</a>
      </div>
    </div>
  </div>)
}