import logo from './logo.svg';
import { useEffect, useState } from 'react';
import './App.css';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

/* Pages */
import Homepage from './components/Homepage';
import AboutPage from './components/PageAbout';
import Design from './components/PageDesign';
import ContactPage from './components/PageContact';
import StoryPage from './components/PageStory';
import Menu from './components/Menu';
import SeriesOne from './components/PageSeriesOne';
import SeriesTwo from './components/PageSeriesTwo';
import Landing from './components/Landing';
import BookPage from './components/BookCookieOne';
import Distro from './components/PageDistro';
import FAQ from './components/PageFAQ';
import Subscribe from './components/PageSubscribe'
import Press from './components/PagePress';
import CartPage from './components/Cart';
import Return from './components/ReturnPage';

import Vivek from './components/BookPageVivek';
import Bora from './components/BookPageBora';
import McKenzie from './components/BookPageMcKenzie';
import Enrique from './components/BookPageEnrique';
import CookieTwo from './components/BookPageCookieTwo';
import RayMike from './components/BookPageRayMike';
import Eileen from './components/BookPageEileen';
import Dodie from './components/BookPageDodie';
import Ashbery from './components/BookPageAshbery';
import Beckmann from './components/BookPageBeckmann';
import Daumal from './components/BookPageDaumal';

//import CartPage from './components/Cart';
import { useRecoilState } from 'recoil';
import { cartState } from './atoms';


function App() {
  const [cart, setCart] = useRecoilState(cartState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem('hanumanCart');
    if (data && JSON.parse(data)) {
      setCart(JSON.parse(data));
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      localStorage.setItem('hanumanCart', JSON.stringify({ ...cart }));
    }
  }, [cart]);

  return (
    <Router>
      <div className="App">
        {/*<div class='bgNoise'></div>*/}
        <div className='appContainer'>
          <header className="App-header">
            <LogoSpace />
            <Menu />
          </header>
          <div className='appRouter'>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/books" element={<Landing />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/design" element={<Design />} />
              <Route path="/series/one" element={<SeriesOne />} />
              <Route path="/series/two" element={<SeriesTwo />} />
              <Route path="/books/garden-of-ashes-cookie-mueller" element={<BookPage />} />
              <Route path="/books/kuruntokai-vivek-narayanan" element={<Vivek />} />
              <Route path="/books/grocery-list-bora-chung" element={<Bora />} />
              <Route path="/books/life-story-mckenzie-wark" element={<McKenzie />} />
              <Route path="/books/insistence-as-a-fine-art-enrique-vila-matas" element={<Enrique />} />
              <Route path="/books/fan-mail-frank-letters-crank-calls-cookie-mueller" element={<CookieTwo />} />
              <Route path="/books/frontier-index-raymond-pettibon-mike-top" element={<RayMike />} />
              <Route path="/books/bread-and-water-eileen-myles" element={<Eileen />} />
              <Route path="/books/feminine-hijinx-dodie-bellamy" element={<Dodie />} />
              <Route path="/books/the-ice-storm-john-ashbery" element={<Ashbery />} />
              <Route path="/books/on-my-painting-max-beckmann" element={<Beckmann />} />
              <Route path="/books/the-lie-of-the-truth-rene-daumal" element={<Daumal />} />
              <Route path="/where-to-buy" element={<Distro />} />
              <Route path="/subscriptions" element={<Subscribe />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/press" element={<Press />} />
              <Route path="/frequently-asked-questions" element={<FAQ />} />
              <Route path="/from-the-editors" element={<StoryPage />} />
              <Route path="/return" element={<Return />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

function LogoSpace() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname === '/') {
      document.body.classList.add('homepage');
    } else {
      document.body.classList.remove('homepage');
    }
  }, [location]);

  return (
    <div className='hanumanLogo'>
      <Link to={`${location.pathname === '/' ? '/books' : ''}`}>Hanuman<br />Editions</Link>
    </div>
  )
}

export default App;

