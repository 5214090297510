import { atom, selector } from 'recoil';

export const cartState = atom({
  key: 'cartState',
  default: {}
});

export const cartCountState = selector({
  key: 'cartCountState',
  get: ({ get }) => {
    const filter = get(cartState);

    let total = 0;
    const items = Object.values(filter);
    for (let i = 0; i < items.length; i++) {
      total += items[i].count;
    }
    return total;
  },
});

export const cartTotalState = selector({
  key: 'cartTotalState',
  get: ({ get }) => {
    const cart = get(cartState);
    const items = Object.values(cart);
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      let count = items[i].count;
      total += count * items[i].price;
    }
    return total;
  },
});