import React from 'react';

export default function PagePress() {

  document.title = `Press | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Contact information and F.A.Q.s for Hanuman Editions.`);

  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>Press</h1>

        <p>Press kit coming soon. Please direct all inquiries to <a href='mailto:press@hanumaneditions.com'>press@hanumaneditions.com</a></p>
      </div>
    </div>
  )
}