import React, { useState } from 'react';
import { TextControl, TextAreaControl } from '../InputControls';
import { Link } from 'react-router-dom';
import { makeRequest, validateEmail } from '../../utils';

export default function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  document.title = `Contact Us | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Contact information and F.A.Q.s for Hanuman Editions.`);


  const submit = async () => {
    if (!validateEmail(email)) {
      setStatusMessage('Please enter a valid email address.');
      return;
    } else if (!message) {
      setStatusMessage('Please enter a message.');
      return;
    } else if (!name) {
      setStatusMessage('Please enter your name.');
      return;
    }
    let body = {
      email: email,
      message: message,
      name: name,
    };
    await makeRequest('contact', 'POST', body);
    setStatusMessage('Thanks! We will be in touch shortly.');
    setSubmitted(true);
  }

  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>Contact Us</h1>

        <p>Please <Link to='/frequently-asked-questions'>read the F.A.Q.s</Link> before getting in touch.</p>

        <p>For inquiries, including press and publicity, as well as distribution, contact us using the form below, or email us at <a href='mailto:info@hanumaneditions.com'>info@hanumaneditions.com</a>.</p>

        <hr />

        {!submitted && <div>
          <div style={{ marginBottom: '24px' }}>
            <TextControl
              label='Your Name'
              value={name}
              change={setName}
            />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <TextControl
              label='Your Email'
              value={email}
              change={setEmail}
            />
          </div>
          <div style={{ marginBottom: '24px' }}>
            <TextAreaControl
              label='Message'
              value={message}
              rows={4}
              change={setMessage}
            />
          </div>


          <button className='signup-button' onClick={submit}>Submit</button>
        </div>}

        {statusMessage && <div className={`emailMessage ${submitted && 'emailSuccess'}`}>{statusMessage}</div>}
      </div>
    </div>
  )
}