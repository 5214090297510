import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartState, cartTotalState } from '../../atoms';
import { TextControl, CountryControl, BinaryControl } from '../InputControls';
import './style.css';
import CheckoutForm from './cart';
import { calculateShipping } from '../../utils';

export default function CartPage() {
  const [items, setItems] = useState([]);
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(true);
  const [country, setCountry] = useState('US');
  const [shipping, setShipping] = useState(5);
  const [shippingMethod, setShippingMethod] = useState('');
  const cartTotal = useRecoilValue(cartTotalState);
  const [message, setMessage] = useState('');
  const [checkingOut, setCheckingOut] = useState(false);
  const [express, setExpress] = useState(false);

  const [cart, setCart] = useRecoilState(cartState);

  document.title = `Cart | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Checkout to complete your order.`);

  const debounceDelay = 750;
  const debounceTimer = useRef(0);
  const controller = useRef();

  const updateShipping = async () => {
    clearTimeout(debounceTimer.current);
    controller.current?.abort();
    controller.current = new AbortController();
    debounceTimer.current = setTimeout(async () => {
      let rates = await calculateShipping(items, country, express);
      setShipping(rates.cost);
      setShippingMethod(rates.method)
    }, debounceDelay);

  }

  useEffect(() => {
    updateShipping();
  }, [items, country, express])


  useEffect(() => {
    setItems(Object.values(cart));
  }, [cart]);

  const changeQuantity = (id, no) => {
    let newCart = { ...cart };
    let newItem = { ...cart[id] };
    newItem.count = newItem.count += no;
    newCart[id] = newItem;
    setCart(newCart);
  }

  const removeItem = (id) => {
    let newCart = { ...cart };
    delete newCart[id];
    setCart(newCart);
  }

  const checkout = async () => {
    setMessage('');
    if (!email) {
      setMessage('Please enter a valid email');
      return;
    } else if (!country) {
      setMessage('Please select a country for shipping');
      return;
    }
    setCheckingOut(true);
  }

  return (
    <div className='pageContainer cartPage'>
      <div>
        <h1>Your Order</h1>
        {items.map((item) =>
          <div className='cartItem'>
            <div className='cartItemImage cartCell'>
              <div><img src={`${item.image}`} alt={`Cover for ${item.title}`} /></div>
            </div>
            <div className='cartDetails'>
              <div className='cartCellContents cartCellDetailInfo'>
                <div className='cartItemTitle'>{item.title}</div>
                <div className='cartItemAuthor'>{item.author}</div>
              </div>
              <div className='cartCell price'>
                <div className='cartCellContents'>
                  ${item.price}
                </div>
              </div>
              <div className='cartCell'>
                <div className='cartCellContents itemCountControls'>
                  <button className='cartIncrement' disabled={item.count < 2} onClick={() => { changeQuantity(item.id, -1) }}><span>-</span></button>
                  <div className='cartItemCount'>
                    <div className='cartItemCountNo'>{item.count}</div>
                  </div>
                  <button className='cartIncrement' onClick={() => { changeQuantity(item.id, 1) }}><span>+</span></button>
                </div>
              </div>
              <div className='cartCell'>
                <button className='removeButton' onClick={() => { removeItem(item.id) }}><i className="fa-solid fa-trash"></i></button>
              </div>
            </div>
          </div>
        )}

        {items.length === 0 ? <div>There is nothing in your cart</div> :
          <>
            <div className='cartItem shipping'>
              <div></div>
              <div>{express ? 'Express' : 'Standard'} Shipping</div>
              <div>${shipping.toFixed(2)}</div>
              <div></div>
            </div>
            <div className='cartItem subtotal'>
              <div></div>
              <div>Subtotal</div>
              <div>${(cartTotal + shipping).toFixed(2)}</div>
              <div></div>
            </div>
          </>
        }
      </div>
      <div>
        <TextControl
          label='Your Email'
          value={email}
          change={setEmail}
        />
        <BinaryControl
          label='Please keep me updated on future publications and announcements'
          value={consent}
          change={setConsent}
          options={['Yes', 'No']}
        />
        <CountryControl
          value={country}
          change={setCountry}
          label='Shipping To'
        />
        <BinaryControl
          label='Do you need express shipping?'
          value={express}
          change={setExpress}
          options={['Yes', 'No']}
          help={`Standard shipping is ${country === 'US' ? '4–8 days.' : '2–3 weeks.'} Express shipping is approximately 2-3 business days.`}
        />
        {message && <div className='cartMessage'>{message}</div>}
        <button disabled={items.length === 0} className='checkoutButton' onClick={checkout}>Checkout</button>
        {checkingOut && <CheckoutForm cart={cart} shipping={shipping} method={shippingMethod} close={() => { setCheckingOut(false) }} country={country} email={email} consent={consent} />}
      </div>
    </div>
  )
}