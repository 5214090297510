import React from 'react';

export default function CartIcon() {
  return (
    <svg id="cart-icon-small" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 244.64 167.67">
      <g id="uuid-5ed59983-3c5c-4dbe-8a9b-18b2bc63d301" data-name="Layer 1">
        <circle class="cart-stroke-one" cx="75.84" cy="147.41" r="18.26" />
        <path class="cart-stroke-two" d="M6,8.89c7.72-.93,15.45-1.85,23.17-2.78,4.9-.34,13.71-.19,21.71,5.09,7.28,4.8,12.18,11.29,13.75,19.54,5.79,30.39,11.58,60.78,17.37,91.18.24,2.17.48,4.34.72,6.51,44.06.24,88.12.48,132.18.72" />
        <circle class="cart-stroke-one" cx="190.39" cy="147.41" r="18.26" />
        <path class="cart-stroke-two" d="M70.42,29.29h128.42c1.54-.09,3.81.18,5.98,2.27,4.05,3.89,5.07,11.55,6.44,22.68,1.57,12.66,2.35,18.98,0,24.95-2.59,6.57-7.3,8.48-9.21,9.07-40.66,8.57-81.32,17.13-121.97,25.7" />      </g>
    </svg>
  )
}