const api = window.location.hostname === 'localhost' ? 'http://localhost:3009/' : window.location.hostname === '35.164.94.89' ? 'http://35.164.94.89:3009/' : 'https://hanumaneditions.com/api/';

export const returnAPI = () => {
  return api;
}

const getHeads = (method, body = null) => {
  let heads = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  if (body) {
    heads.body = JSON.stringify(body);
  }
  return heads;
}

export const makeRequest = async (url, method, body) => {
  url = url.includes('http') ? url : api + url;
  let heads = getHeads(method, body ? body : null);
  let response = await fetch(url, heads);
  let data = await response.json();
  return (data);
}

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const updateCartState = (cart, item, quantity = 1) => {
  let newCart = { ...cart };
  let id = item.id;
  if (newCart[id]) {
    let newItem = { ...newCart[id] }
    newItem.count = newCart[id].count + quantity;
    newCart[id] = newItem;
  } else {
    newCart[id] = item;
    newCart[id].count = quantity;
  }
  return newCart;
}

export const calculateShipping = async (items, country, express) => {
  let weight = 0;
  let count = 0;
  items.forEach((item) => {
    weight += item.weight * item.count;
    count += item.count;
  })
  let request = {
    ounces: weight,
    country: country,
    postalCode: '',
    express: express,
    free: false,
    items: count,
    noPacking: true,
  }
  try {
    let rateData = await fetch('https://asterismbooks.com/api/shipping-rates', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });
    let rate = await rateData.json();
    return {
      cost: rate.shipmentCost,
      method: rate.method
    }
  } catch (e) {
    let us = country === 'US' || country === '';
    let lbs = Math.ceil(weight / 16);
    let base = us && !express ? 3.75 : us && !express ? 10 : express ? 24 : 8;
    return {
      cost: base + (lbs * .75),
      method: express ? 'Express shipping' : 'Standard shipping'
    }
  }

}