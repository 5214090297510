import React, { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import './style.css';
import { returnAPI } from '../../utils';

const key = window.location.hostname === 'hanumaneditions.com' ? 'pk_live_51P5aocFoScMjUSrmykhjIroMxHF1qS4Vq0QwYJY7ClulF7WUiQxg3cBdryL0nsobhzfUYBzCyqTwoAhGfO1bPaPh00MnRNWRl1' : 'pk_test_51P5aocFoScMjUSrmMEMifx7e37RBrwsxCxIH1ZIRtY4uhRPTPfranOHgIyDCO8cFoCz7hNrl6qkCfiyYs5qufHVz00KSu9qX34';

const stripePromise = loadStripe(key);

export default function CheckoutForm({ cart, close, shipping, method, email = '', consent, country = 'US' }) {
  let items = Object.values(cart);
  let data = {
    items: items,
    shipping: shipping,
    method: method,
    country: country,
    email: email,
    consent: consent,
    host: `${window.location.host === 'hanumaneditions.com' ? 'https://' : 'http://'}${window.location.host}`
  }

  const fetchClientSecret = useCallback(() => {
    let api = returnAPI();
    return fetch(`${api}create-checkout-session`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, [cart]);

  const options = { fetchClientSecret };

  return (
    <div id="checkoutModal">
      <div id="checkoutModalContent">
        <div className='modalBack'><button onClick={close}>&larr; Go Back</button></div>
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  )
}

