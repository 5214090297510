export const priceIndex = {
  '9798990416529': {
    title: 'Grocery List',
    prod: 'price_1PTAz2FoScMjUSrm2WfnV996',
    test: 'price_1PTBM1FoScMjUSrmDaiSQ6l4'
  },
  '9798990416512': {
    title: 'Frontier Index',
    prod: 'price_1QK7xcFoScMjUSrmoaNSPo2g',
    test: 'price_1QK3jlFoScMjUSrmjcplpfDi'
  },
  '9798990416505': {
    title: 'Insistence as a Fine Art',
    prod: 'price_1QK7yUFoScMjUSrm8LVWoSxV',
    test: 'price_1QK3rqFoScMjUSrmT9xu5wp4'
  },
  '9798989378098': {
    title: 'The Kuruntokai and Its Mirror',
    prod: 'price_1QK7zRFoScMjUSrmQQ15TYRL',
    test: 'price_1QK3sdFoScMjUSrmCHGj9jI8'
  },
  '9798989378081': {
    title: 'Fan Mail, Frank Letters and Crank Calls',
    prod: 'price_1QK80eFoScMjUSrmiMrnzTR1',
    test: 'price_1QK3txFoScMjUSrmis4rzQpf'
  },
  '9798989378067': {
    title: 'Life Story',
    prod: 'price_1QK7zzFoScMjUSrmjVAxY1Qr',
    test: 'price_1PTFJUFoScMjUSrmH8cmBjy9'
  },
  '9798989378043': {
    title: 'Bread and Water',
    prod: 'price_1QK82jFoScMjUSrm6fKXonmd',
    test: 'price_1QK45XFoScMjUSrmEuDLKEfY'
  },
  '9798989378036': {
    title: 'Garden of Ashes',
    prod: 'price_1QK83GFoScMjUSrmW3VgNFGS',
    test: 'price_1QK46aFoScMjUSrmXasR7KO4'
  },
  '9798989378050': {
    title: 'The Lie of the Truth',
    prod: 'price_1QK84hFoScMjUSrmfOan4GbD',
    test: 'price_1QK47AFoScMjUSrmsUrDOVl2'
  },
  '9798989378029': {
    title: 'Feminine Hijinx',
    prod: 'price_1QK83tFoScMjUSrmQlWAUPgt',
    test: 'price_1QK4CiFoScMjUSrmCDRW5eCe'
  },
  '9798989378012': {
    title: 'The Ice Storm',
    prod: 'price_1QK4HlFoScMjUSrmYObKylLv',
    test: 'price_1QK4DVFoScMjUSrmjIYxoUos'
  },
  '9798989378005': {
    title: 'On My Painting',
    prod: 'price_1QK85qFoScMjUSrmGTpUp1Pv',
    test: 'price_1QK4DwFoScMjUSrm72esR3hO'
  },
  'hanuman_box_02_tb': {
    title: 'Tabriz Box',
    prod: 'price_1QK88dFoScMjUSrmMk2uI3ul',
    test: 'price_1QK4FgFoScMjUSrm0UEFHp5S'
  },
  'hanuman_box_02_ct': {
    title: 'Citrine Box',
    prod: 'price_1QK876FoScMjUSrmywTWW7eu',
    test: 'price_1QK4F7FoScMjUSrmrk5JOOrb'
  },
  'hanuman_box_02_nb': {
    title: 'New Blue Box',
    prod: 'price_1Pu1z5FoScMjUSrmbNK2ZDX7',
    test: 'price_1QK4FzFoScMjUSrmYBKtVdUz'
  },
  'hanuman_box_02_fl': {
    title: 'Flamingo Box',
    prod: 'price_1QK87qFoScMjUSrm5gBshLJ8',
    test: 'price_1QK4GHFoScMjUSrmh5HNuRlR'
  }
}