import React, { useEffect, useState } from 'react';
import { makeRequest, updateCartState } from '../../utils';
import { useRecoilState } from 'recoil';
import { cartState } from '../../atoms';
import './style.css';
import { priceIndex } from './prices';


export default function AddToCart({ id, context, outOfStock = false }) {
  const [inStock, setInStock] = useState(true);
  const [cart, setCart] = useRecoilState(cartState);
  const [product, setProduct] = useState({});
  const [price, setPrice] = useState('');
  const [cost, setCost] = useState(15.99);

  const checkInStock = async () => {
    console.log(outOfStock);
    if (outOfStock) {
      console.log('Got here')
      setInStock(false);
      return;
    }
    let data = await makeRequest(`https://asterismbooks.com/api/product?id=${id}`);
    setProduct(data);
    setCost(data.prices.retail);
    setInStock(data.inventory.asterism > 0);
  }

  const addToCart = () => {
    let item = {
      id: product._id,
      priceId: price,
      title: product.title,
      author: product.authors.join(','),
      image: product.cover['300'],
      price: product.prices.retail,
      weight: product.dimensions.weight,
    }
    let newCart = updateCartState(cart, item);
    setCart(newCart);
  }

  useEffect(() => {
    if (id) {
      try {
        setPrice(window.location.hostname === 'hanumaneditions.com' ? priceIndex[id].prod : priceIndex[id].test)
      } catch (e) {
        console.log(id);
      }
      checkInStock()
    } else {
      setInStock(false);
    }
  }, [id]);

  return (
    <>
      {inStock ? <button className={`addToCartButton ${context}`} onClick={addToCart}>
        <span>+ Cart</span>
      </button> : <button className={`addToCartButton ${context}`} disabled>Out of Stock</button>}
    </>
  )
}