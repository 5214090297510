import React, { useState, useEffect } from 'react';
import { makeRequest } from '../../utils';
import { useSetRecoilState } from 'recoil';
import { cartState } from '../../atoms';

export default function Return() {
  const [email, setEmail] = useState('');
  const setCart = useSetRecoilState(cartState);

  const getReceiptNo = async (id) => {
    let data = await makeRequest(`session-status?session_id=${id}`);
    setEmail(data.customer_email);
  }

  useEffect(() => {
    setCart({});
    const urlSearchParams = new URLSearchParams(window.location.search);
    const session = urlSearchParams.get('session_id');
    if (session) {
      getReceiptNo(session);
    }
  }, [])


  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>Thank you!</h1>
      </div>
      <div>
        <p>Your order has been placed! You will receive a receipt at <strong>{email}</strong> shortly.</p>
      </div>
    </div>
  )
}