import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import { makeRequest, validateEmail } from '../../utils';
import CartIcon from '../../icons/cart';
import AddToCart from '../AddToCart';

export default function AboutPage() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  document.title = `Series One | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `First series includes books by John Ashbery, Cookie Mueller, Eileen Myles, René Daumal, Max Beckmann, and Dodie Bellamy`);


  return (
    <div className='pageContainer booksLanding'>

      <div className='booksPage'>

        <h1 className='gold'>Series One</h1>
        <p className='seriesGraf'>Hanuman Editions pays homage to the editors and contributors of Hanuman Books, from 1986 to 1993. Tracing its roots at Kalakshetra Press in present day Chennai, southern India and the Chelsea Hotel in New York City, our first series comprises six reissued books (by John Ashbery, Cookie Mueller, Eileen Myles, Dodie Bellamy, René Daumal, and Max Beckmann) as a dedication to the original project.</p>

        <h3 className='seriesSubhead'>Individual Titles</h3>

        <div className='new-grid'>

          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/on-my-painting-max-beckmann'>
                  <img src='../../beckmann2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>On My Painting</div>
                <div className='nb-author'>Max Beckmann</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378005'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/the-ice-storm-john-ashbery'>
                  <img src='../../ashbery2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Ice Storm</div>
                <div className='nb-author'>John Ashbery</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378012'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/feminine-hijinx-dodie-bellamy'>
                  <img src='../../bellamy2.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Feminine Hijinx</div>
                <div className='nb-author'>Dodie Bellamy</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378029'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/garden-of-ashes-cookie-mueller'>
                  <img src='../../mueller.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Garden of Ashes</div>
                <div className='nb-author'>Cookie Mueller</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378036'} text={'Add to Cart'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/bread-and-water-eileen-myles'>
                  <img src='../../myles.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Bread and Water</div>
                <div className='nb-author'>Eileen Myles</div>
              </div>
              <div>
                <AddToCart context='landing' id={'9798989378043'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/the-lie-of-the-truth-rene-daumal'>
                  <img src='../../daumal.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Lie of the Truth</div>
                <div className='nb-author'>René Daumal</div>
              </div>
              <div>
                <AddToCart context='landing' id={'9798989378050'} />
              </div>
            </div>
          </div>

        </div>
        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '96px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series One box sets produced in a hand-numbered edition of 250.*</p>
        <p className='finePrint'>* Sold out</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series1_em.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Emerald</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>
          <div className='boxGrid-item'>
            <img src='../../series1_tq.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Turquoise</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>
          <div className='boxGrid-item'>
            <img src='../../series1_cp.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Candy Pink</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series1_az.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Azure Blue</div>
              <div>
                <AddToCart context='landing' outOfStock={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}