import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';
import AddToCart from '../AddToCart';


export default function BookPage() {

  document.title = `Insistence as a Fine Art by Enrique Vila-Matas, translated by Kit Schluter | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `From a mind once described as “an endless labyrinth in which all forks lead to literature,”  <em>Insistence as a Fine Art</em> trades the ekphrastic form for passage through a hall of interlocutors, mirrors, and guides (Nietzsche, Gadda, Calvino, Orson Welles). Thus Enrique Vila-Matas’ study of an artwork, Romero de Torres’s <em>La Buenaventura</em> (<em>The Fortune Teller</em>) from the Thyssen-Bornemisza Museum’s permanent collection, swiftly becomes much more, spinning out with signature erudition into a whirlwind meditation on painting, history, and the opacity of the present tense. As one turn folds into another, voices rise and drift into a keenly elliptical flow.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../Insistence.png' /></div>
      <div className='n-image n-image-spine'><img src='../../evmspine.png' /></div>
      <div className='n-image n-image-back'><img src='../../evmback.png' /></div>
      <div className='n-image n-image-2'><img src='../../evm2.png' /></div>
      <div className='n-image n-image-3'><img src='../../evm3.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/two'>Series Two</Link> | Spring 2024</div>
      <h1 className='n2-book-title'>Insistence as a Fine Art</h1>
      <div className='n2-book-author'>Enrique Vila-Matas, trans. Kit Schluter</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9904165-0-5</div>
        <div style={{ marginBottom: '6px' }}>80pp | $14.99 USD</div>
        <div className='buyingOptions'>
          <AddToCart context={'product'} id={'9798990416505'} />
        </div>

      </div>
      <div className='mainText'>

        <p><em>“In my particular case, and following what I’ve been writing here in this diary, I would say that some of the heterogeneous elements that influenced me when I chose <em>The Fortune Teller</em> were: the kindness and genius of the underappreciated Cordovan painter; Nietzsche’s absence; a playing card frozen in the course of time; my activism on behalf of the Multiple; the landline phone; loose threads hanging from an homemade Odradek; the world as an infinite knot; and a fleeting glimpse of eternity on Earth.”</em></p>
        <p>From a mind once described as “an endless labyrinth in which all forks lead to literature,”  <em>Insistence as a Fine Art</em> trades the ekphrastic form for passage through a hall of interlocutors, mirrors, and guides (Nietzsche, Gadda, Calvino, Orson Welles). Thus Enrique Vila-Matas’ study of an artwork, Romero de Torres’s <em>La Buenaventura</em> (<em>The Fortune Teller</em>) from the Thyssen-Bornemisza Museum’s permanent collection, swiftly becomes much more, spinning out with signature erudition into a whirlwind meditation on painting, history, and the opacity of the present tense. As one turn folds into another, voices rise and drift into a keenly elliptical flow.</p>
        <p>Barcelonan writer <strong>Enrique Vila-Matas</strong> is among the most prominent contemporary Spanish authors, a renown that makes him no less avant-garde. He studied law and journalism, worked as an editor on the film journal Fotogramas, and lived in Paris between 1974 and 1976. Vila-Matas is the author of the prize-winning <em>Dublinesque</em> (2010), which along with his numerous short stories, essays, columns and articles, conveys a deeply intertextual commitment to the construction of writing. Vila-Matas’ work has been translated into 32 languages and awarded numerous prizes, including the 2001 Premio Rómulo Gallegos and the prestigious Prix Médicis for the best foreign novel in France, in 2003. The author belongs to the Orden del Finnegans, whose members are committed to veneration of Joyce’s masterpiece <em>Ulysses</em>.</p>
        <p><strong>Kit Schluter</strong> is author of <em>Cartoons</em>, a collection of stories and drawings, and <em>Pierrot's Fingernails</em>, a book of poems. Among his recent and forthcoming translations are by Rafael Bernal, Copi, bruno darío, and Marcel Schwob. He lives in Mexico City and designs books for a living.</p>

      </div>
      <div style={{ marginTop: '12px' }}>
        <h4>More Buying Options</h4>
        <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/insistence-as-a-fine-art-enrique-vila-matas'>Buy from Asterism (US/Canada)</a><br />
        <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/enrique-vila-matas-trans-kit-schluter-insistence-as-a-fine-art'>Buy from PKB (UK)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798990416505-enrique-vila-matas-insistence-as-a-fine-art'>Buy from Idea Books (Europe/Asia)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/enrique-vila-matas-insistence-as-a-fine-art'>Buy from Perimeter (Australia/Oceania)</a>
      </div>
    </div>
  </div>)
}