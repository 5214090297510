import React, { useEffect, useState } from 'react';
import './style.css';

export function TextControl({ value, change, label = '', help = '', placeholder = '', valid = true, type = 'text', classlist = '', disabled = false, name = '', required = false, onEnter = null, bypass = false, autocomplete = 'off' }) {
  const [localValue, setLocalValue] = useState(value);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onEnter) {
      change(localValue);
      setTimeout(() => {
        onEnter();
      }, 30)
    }
  }

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className='inputControl'>
      {label && <label for={name} className='inputControl__label'>{label} {required && <span className='required'>*</span>}</label>}
      <input
        disabled={disabled ? 'disabled' : ''}
        id={name}
        autocomplete={autocomplete}
        type={type}
        className={`${valid && 'valid'} ${classlist}`}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={localValue}
        onBlur={() => change(localValue)}
        onChange={(e) => { bypass ? change(e.target.value) : setLocalValue(e.target.value) }} />
      {help && <div className='inputControl__help' dangerouslySetInnerHTML={{ __html: help }}></div>}
    </div>
  )
}


export function SelectControl({ value, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false, name = '' }) {
  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      <select id={name} disabled={disabled ? 'disabled' : ''} className={`${valid && 'valid'}`} value={value} onChange={(e) => { change(e.target.value) }}>
        {options.length > 0 && options.map((opt) =>
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        )}
      </select>
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function BinaryControl({ value, change, label = '', help = '', options = ['Yes', 'No'], classList = null, disabled = false, name = '' }) {
  return (
    <div className={`inputControl binaryControl ${classList}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      {!disabled && <div className='binaryChooser'>
        <div role='button' tabIndex={0} aria-label='Yes' className={`binaryChooser__opt ${value === true && 'binaryChooser__opt-chosen'}`} onClick={() => { change(true) }}>{options[0]}</div>
        <div role='button' tabIndex={0} aria-label='No' className={`binaryChooser__opt ${value === false && 'binaryChooser__opt-chosen'}`} onClick={() => { change(false) }}>{options[1]}</div>
      </div>}
      {disabled && <div className='binaryChooser binaryChooser-disabled'>
        <div role='button' aria-label='Yes' className={`binaryChooser__opt ${value === true && 'binaryChooser__opt-chosen'}`}>{options[0]}</div>
        <div role='button' aria-label='No' className={`binaryChooser__opt ${value === false && 'binaryChooser__opt-chosen'}`}>{options[1]}</div>
      </div>}
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}


export function TextAreaControl({ value, change, label = '', help = '', placeholder = '', valid = true, type = 'text', classlist = '', disabled = false, rows = 3, name = '', description = '' }) {
  return (
    <div className='inputControl'>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      {description && <p className='form-description'>{description}</p>}
      <textarea id={name} disabled={disabled ? 'disabled' : ''} rows={rows} type={type} className={`${valid && 'valid'} ${classlist}`} placeholder={placeholder} value={value} onChange={(e) => { change(e.target.value) }} />
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}


export function MultiSelect({ choices = [], change, values = [], label = '', help = '', disabled = false }) {

  const updateCategories = (name) => {
    let newValues = [...values];
    let index = values.indexOf(name);
    if (index === -1) {
      newValues.push(name);
    } else {
      newValues.splice(index, 1)
    }
    change(newValues);
  }

  return (
    <div className='inputControl' key={values.join('-')}>
      {label && <label className='inputControl__label'>{label}</label>}
      <div className='categoryList'>
        {choices.map((choice) =>
          <button key={choice} disabled={disabled} className={`categoryPill ${values.indexOf(choice) !== -1 ? 'categoryPill-active' : ''}`} onClick={() => { updateCategories(choice) }}>{choice}</button>
        )}
      </div>

      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function MultiSelectObjects({ choices = [], change, values = [], label = '', help = '', disabled = false }) {

  const updateCategories = (choice) => {
    let newValues = [...values];
    let index = isInList(choice.value);
    if (index === -1) {
      newValues.push(choice);
    } else {
      newValues.splice(index, 1)
    }
    change(newValues);
  }

  const isInList = (value) => {
    let isIt = -1;
    values.forEach((val, index) => {
      if (val.value == value) {
        isIt = index;
      }
    })
    return isIt;
  }

  return (
    <div className='inputControl' key={values.join('-')}>
      {label && <label className='inputControl__label'>{label}</label>}
      <div className='categoryList'>
        {choices.map((choice) =>
          <button key={choice.value} disabled={disabled} className={`categoryPill ${isInList(choice.value) >= 0 ? 'categoryPill-active' : ''}`} onClick={() => { updateCategories(choice) }}>{choice.label}</button>
        )}
      </div>

      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function NumberControl({
  value,
  change,
  label = '',
  help = '',
  placeholder = '',
  valid = true,
  name = '',
  classlist = '',
  disabled = false,
  min = 0,
  max = null,
  step = 1,
  important = '' }) {


  return (
    <div className='inputControl'>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      {important && <div className='form-important'>{important}</div>}
      <input
        id={name}
        disabled={disabled ? 'disabled' : ''}
        step={step}
        type='number'
        min={min}
        className={`${valid && 'valid'} ${classlist}`}
        max={max}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { change(parseFloat(e.target.value)) }} />
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}

export function DateControl({ value, change, label = '', help = '', required = false, disabled = false, classlist = '', valid = true, name = '' }) {
  const prepareDateForInput = (date) => {
    console.log(date);

    if (date) {
      try {
        return new Date(date).toISOString().split('T')[0];
      } catch (e) {
        return ''
      }
    } else {
      return '';
    }
  };

  const prepareDateTimeForInput = (date) => {
    if (date) {
      return new Date(date).toISOString();
    } else {
      return '';
    }
  }

  return (
    <div className='inputControl'>
      {label && <label for={name} className='inputControl__label'>{label} {required && <span className='required'>*</span>}</label>}
      <input
        id={name}
        disabled={disabled ? 'disabled' : ''}
        type={'date'}
        value={prepareDateForInput(value)}
        className={`${valid && 'valid'} ${classlist}`}
        onChange={(e) => { change(e.target.value) }} />
      {help && <div className='inputControl__help' dangerouslySetInnerHTML={{ __html: help }}></div>}
    </div>
  )
}

const useForceUpdate = () => useState()[1];

export function FileControl({ reference, image = false, label = '', help = '', valid = true, type = 'text', classlist = '', disabled = false, accept = '', multiple = false, name = '' }) {
  const forceUpdate = useForceUpdate();

  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for={name} className='inputControl__label'>{label}</label>}
      <input id={name} onChange={forceUpdate} disabled={disabled ? 'disabled' : ''} type='file' className={`${valid && 'valid'}`} accept={accept} ref={reference} multiple={multiple ? 'multiple' : false} />
      {help && <div className='inputControl__help'>Accepted file types: {accept}</div>}

      {image && reference.current && reference.current.files.length === 1 &&
        <img className='imagePreview' src={URL.createObjectURL(reference.current.files[0])} />
      }
      {image && reference.current && reference.current.files.length > 1 &&
        Array.from(reference.current.files).map((img) =>
          <img key={URL.createObjectURL(img)} className='imagePreview' src={URL.createObjectURL(img)} />
        )
      }
    </div>
  )
}


export function CountryControl({ value, change, label = '', help = '', valid = true, classlist = '', disabled = false }) {
  const countries = require('./countries.json');
  return (
    <div className={`inputControl ${classlist}`}>
      {label && <label for='Country' className='inputControl__label'>{label}</label>}
      <select autocomplete='country' name='Country' disabled={disabled ? 'disabled' : ''} className={`${valid && 'valid'}`} value={value} onChange={(e) => { change(e.target.value) }}>
        <option value=''>--</option>
        {countries.map((opt) =>
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        )}
      </select>
      {help && <div className='inputControl__help'>{help}</div>}
    </div>
  )
}