import React, { useEffect, useState } from 'react';
import CartIcon from '../../icons/cart';
import { Link } from 'react-router-dom';
import AddToCart from '../AddToCart';


export default function BookPage() {

  document.title = `Fan Mail, Frank Letters and Crank Calls by Cookie Mueller | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Following the publication of Cookie Mueller’s Garden of Ashes in our first series, we are thrilled to reissue her first contribution to the Hanuman canon: Fan Mail, Frank Letters, and Crank Calls.`);


  return (<div className='pageContainer n2-book'>
    <div className='n-book-images'>
      <div className='n-image n-primary-image'><img src='../../FanMail.png' /></div>
      <div className='n-image n-image-spine'><img src='../../cookiespine.png' /></div>
      <div className='n-image n-image-back'><img src='../../fanmailback.png' /></div>
      <div className='n-image n-image-2'><img src='../../fanmail2.png' /></div>
      <div className='n-image n-image-3'><img src='../../fanmail3.png' /></div>
    </div>

    <div className='n2-book-data'>
      <div className='n2-book-series'><Link to='/series/two'>Series Two</Link> | Spring 2024</div>
      <h1 className='n2-book-title'>Fan Mail, Frank Letters and Crank Calls</h1>
      <div className='n2-book-author'>Cookie Mueller</div>

      <div className='n2-book-stats'>
        <div>ISBN 979-8-9893780-8-1</div>
        <div style={{ marginBottom: '6px' }}>96pp | $14.99 USD</div>
        <div className='buyingOptions'>
          <AddToCart context={'product'} id={'9798989378081'} />
        </div>

      </div>
      <div className='mainText'>


        <p><em>
          “Hello?”<br></br>
          “Yeah, hi Georgia!”<br></br>
          “This isn’t Georgia. Who’s<br></br>
          calling please?”<br></br>
          “John Huston.”<br></br>
          “From the grave ?”<br></br>
        </em></p>

        <p>Following the publication of Cookie Mueller’s <em>Garden of Ashes</em> in our first series, we are thrilled to reissue her first contribution to the Hanuman canon: <em>Fan Mail, Frank Letters, and Crank Calls</em>, an epistolary portrait of the absurdity of human connection. Her book also doubles as a shrine to channels of analogue communication between private and public personas that have since metastasized in our climate of microcelebrity ideation. Sexy and hilarious, Mueller’s text embraces the discomfiting nature of desire with empathy and trademark wit. </p>

        <p><strong>Cookie Mueller</strong> (1949–1989) was an American writer, actress, and advice columnist, best remembered as a regular cast member of some of the director John Waters's groundbreaking films, including <em>Multiple Maniacs, Pink Flamingos, Female Trouble</em>, and <em>Desperate Living</em>. She died from AIDS-related complications in 1989.</p>

      </div>
      <div style={{ marginTop: '12px' }}>
        <h4>More Buying Options</h4>
        <a rel='noreferrer' target='_blank' href='https://asterismbooks.com/product/fan-mail-frank-letters-and-crank-calls-cookie-mueller'>Buy from Asterism (US/Canada)</a><br />
        <a rel='noreferrer' target='_blank' href='https://publicknowledgebooks.com/products/cookie-mueller-fan-mail-frank-letters-and-crank-calls'>Buy from PKB (UK)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.ideabooks.nl/9798989378081-cookie-mueller-fan-mail-frank-letters-and-crank-calls'>Buy from Idea Books (Europe/Asia)</a><br />
        <a rel='noreferrer' target='_blank' href='https://www.perimeterbooks.com/products/cookie-mueller-fan-mail-frank-letters-and-crank-calls'>Buy from Perimeter (Australia/Oceania)</a>
      </div>
    </div>
  </div>)
}