import React from 'react';
import { Link } from 'react-router-dom';

export default function StoryPage() {

  document.title = `From the Editors | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `Notes and missives from the editors of Hanuman Editions.`);

  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>Founder's Letter</h1>
        <p className='leftGraf'>It was in 2012 when I first came across Hanuman Books, at a pavement bookstore on the South Bank in London; in particular, Allen Ginsberg’s <em>Your Reason and Blake’s System</em>, in which Ginsberg conveys the importance of William Blake to his own Beat context. Away from my own frame of reference, growing up in India, I was immediately drawn to the book’s visceral quality and to the intrigue of encountering Ginsberg’s name printed under the banner of the mythic figure of Hanuman, a beloved deity within the pantheon of Hindu gods.</p>

        <p className='rightGraf'>In the chapbook, Ginsberg writes, “There are similar revolutionary conditions now as in Blake’s time, similar social and emotional problems,” relating Blake’s work to “the revolutionary fervour of the Sixties in America and a subsequent so-called ‘disillusionment.’” Ginsberg’s observation is trenchant in reflecting on our own conditions of explosive global crises, as we continue to ask: <em>how do we live together in an increasingly fractious, yet interdependent world?</em> Much has transpired since Ginsberg’s time: the advent of the digital, new generational attitudes towards history and art, a redrawing of lines of confrontation with the mainstream and the dismantling of Western-centric knowledge realms—developments which, taken together, impel us to contemplate the ‘cutting edge’ within the public domain in fresh registers of aesthetic experience.</p>

        <p className='leftGraf'>Since that early encounter with the books more than a decade ago, I have been preoccupied with re-imagining Hanuman for our times. Enticed by a vast realm of possibilities, I wrote to Francesco Clemente in 2013, who in turn introduced me to Raymond Foye—with whom I began a correspondence the same year. In 2014, I flew to New York City to meet Raymond at the Chelsea Hotel (where he still partly resides) to discuss the possibility of an exhibition of the Hanuman archive, followed by the publication of a catalogue. We talked about how Hanuman Books came into being, including his friendships with Francesco Clemente, George Scrivani, C. T. Nachiappan who owned the Kalakshetra Press in Chennai (then Madras), India, and Hanuman’s many contributors. I was touched by Raymond’s generosity and warmth towards an archive enthusiast who had shown up at his door all the way from England; and by his passion for avant-garde production, something that seemed elusive in my own generational context.</p>

        <p className='rightGraf'>I was struck by an impulse to have a long-term engagement, both with the books and with what they represent. When I later told Raymond with certainty that I wanted to make a new series of books, he mailed me his entire personal collection of Hanuman books, along with a note giving me his blessing.  Since then, the books have travelled with me wherever I have gone, across time, countries, and homes, like talismans. Meanwhile, Hanuman Editions has had its share of travails in bringing it to being, all of which fortuitously led me to Joshua (Rothes), my friend, co-editor, and co-publisher on the project. We share our deep love for Hanuman’s ethos, as much as our love of the written word and the materiality of book-making. Together, Joshua and I hope to carry forth and open up the legacy of Hanuman Books, one that encompasses new frontiers of universal poetics.</p>

        <p>I look forward to voyaging along with you in all that is to come.</p>

        <p className='signOff'>
          With best wishes,<br></br>
          Shruti Belliappa<br></br>
          Founding Editor, Hanuman Editions
        </p>
      </div>
    </div>
  )
}