import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CartIcon from '../../icons/cart';
import AddToCart from '../AddToCart';

export default function SeriesTwo() {

  document.title = `Series Two | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `First series includes books by John Ashbery, Cookie Mueller, Eileen Myles, René Daumal, Max Beckmann, and Dodie Bellamy`);


  return (
    <div className='pageContainer booksLanding'>

      <div className='booksPage'>
        <h1 className='gold'>Series Two</h1>
        <p className='seriesGraf'>Gathering approaches from the diffuse planetary avant-garde of today, the second series of Hanuman Editions brings together works by McKenzie Wark, Bora Chung, Enrique Vila-Matas, Vivek Narayanan, and the cult duo of Raymond Pettibon and Mike Topp. We also reissue Cookie Mueller’s <em>Fan Mail, Frank Letters, and Crank Calls</em> from the original Hanuman catalogue.</p>

        <h3 className='seriesSubhead'>Individual Titles</h3>


        <div className='new-grid'>

          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/life-story-mckenzie-wark'>
                  <img src='../../LifeStory.png' />
                </Link>
              </div>
              {/*<div className='new-product-controls npc-card'>
                <div className='nb-info'>
                  <div className='nb-title'>Life Story</div>
                  <div className='nb-author'>McKenzie Wark</div>
                </div>
                <div>
                  <AddToCart context={'landing'} id={'9798989378067'} />
                </div>
                <div className='npc-learn'>
                  Learn more...
                </div>
              </div>*/}
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Life Story</div>
                <div className='nb-author'>McKenzie Wark</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378067'} />
              </div>
            </div>
          </div>

          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/grocery-list-bora-chung'>
                  <img src='../../GroceryList.png' />
                </Link>
              </div>
              {/*<div className='new-product-controls npc-card'>
                <div className='nb-info'>
                  <div className='nb-title'>Grocery List</div>
                  <div className='nb-author'>Bora Chung</div>
                </div>
                <div>
                  <AddToCart context={'landing'} id={'9798990416529'} price={'price_1PTBM1FoScMjUSrmDaiSQ6l4'} />
                </div>
                <div className='npc-learn'>
                  Learn more...
                </div>
              </div>*/}

            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Grocery List</div>
                <div className='nb-author'>Bora Chung</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798990416529'} />
              </div>
            </div>

          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/frontier-index-raymond-pettibon-mike-top'>
                  <img src='../../Frontier.png' />
                </Link>
              </div>
              {/*<div className='new-product-controls npc-card'>
                <div className='nb-info'>
                  <div className='nb-title'>The Frontier Index</div>
                  <div className='nb-author'>Raymond Pettibon & Mike Topp</div>
                </div>
                <div>
                  <AddToCart context={'landing'} id={'9798990416512'} />
                </div>
                <div className='npc-learn'>
                  Learn more...
                </div>
              </div>*/}
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Frontier Index</div>
                <div className='nb-author'>Raymond Pettibon & Mike Topp</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798990416512'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/insistence-as-a-fine-art-enrique-vila-matas'>
                  <img src='../../Insistence.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Insistence as a Fine Art</div>
                <div className='nb-author'>Enrique Vila-Matas</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798990416505'} />
              </div>
            </div>
          </div>


          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/kuruntokai-vivek-narayanan'>
                  <img src='../../Kuruntokai.png' />
                </Link>
              </div>


            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>The Kuruntokai and Its Mirror</div>
                <div className='nb-author'>Vivek Narayanan</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378098'} />
              </div>
            </div>
          </div>
          <div className='new-grid-item'>
            <div className='new-grid-cover'>
              <div className='book-cover'>
                <Link to='/books/fan-mail-frank-letters-crank-calls-cookie-mueller'>
                  <img src='../../FanMail.png' />
                </Link>
              </div>
            </div>
            <div className='new-product-controls'>
              <div className='nb-info'>
                <div className='nb-title'>Fan Mail, Frank Letters and Crank Calls</div>
                <div className='nb-author'>Cookie Mueller</div>
              </div>
              <div>
                <AddToCart context={'landing'} id={'9798989378081'} />
              </div>
            </div>
          </div>

        </div>

        <h3 className='seriesSubhead lessMarginBottom' style={{ marginTop: '64px' }}>Limited Edition Box Sets</h3>
        <p className='seriesGraf gold'>Series Two box sets produced in a hand-numbered edition of 500.</p>

        <div className='boxGrid'>
          <div className='boxGrid-item'>
            <img src='../../series2_fm.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Flamingo</div>

              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_fl'} text={'Add to Cart'} />
              </div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_nb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>New Blue</div>

              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_nb'} text={'Add to Cart'} />
              </div>
            </div>
          </div>

          <div className='boxGrid-item'>
            <img src='../../series2_tb.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Tabriz Blue</div>

              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_tb'} text={'Add to Cart'} />
              </div>
            </div>
          </div>


          <div className='boxGrid-item'>
            <img src='../../series2_ct.png' />
            <div className='new-product-controls box'>
              <div className='boxColor'>Citrine</div>
              <div>
                <AddToCart context={'landing'} id={'hanuman_box_02_ct'} text={'Add to Cart'} />
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}