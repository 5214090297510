import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TextControl } from '../InputControls';
import { makeRequest, validateEmail } from '../../utils';

export default function DesignPage() {

  document.title = `Design | Hanuman Editions`;
  document.querySelector('meta[name="description"]').setAttribute('content', `We seek to revive the legacy of Hanuman Books, the legendary and cult series of chapbooks that were printed in southern India and published out of the storied Chelsea Hotel in New York City between 1986 and 1993.`);

  return (
    <div className='pageContainer'>
      <div className='mainText'>
        <h1>Design</h1>
        <p>Hanuman Editions retain the playful yet precise, object-like spirit of Hanuman Books’ original design that, through the work of C. T. Nachiappan, channelled the pocket-sized form of the Hanuman Chalisa, Indian prayer books of 40 invocations to the Hindu god of strength, circulated widely as pithy talismans for spiritual protection. The books’ micro scale also evokes the radical example of Menocchio in Carlo Ginzburg’s <em>The Cheese and the Worms</em> (1976), where the individual imaginary becomes a harbinger of larger currents. We alight on these mixed lineages in a portability-focused present, an age of plenty and distraction primed for a return to objectness. </p>

        <p>Hanuman Editions is invested in creating works that travel ambiently like ideas, looking to histories of art and design with a close eye on the new. All design for Hanuman Editions is done in-house, by Shruti Belliappa and Joshua Rothes. Our ‘Hanuman’ avatar was hand drawn by Joshua Rothes just as Francesco Clemente for Hanuman Books. </p>
        <p>Our website is coded in the tone of architectural blueprints, intimating a holistic envisioning of things to come.</p>

        <p>The design of Hanuman Editions are inspired by works of avant-garde production, past and ongoing.</p>

        <div className='centeredImageContainer'>
          <figure className='center'>
            <img src='../../nds-3.png' />
            <figcaption>Detail, <em>Parc des Princes</em> (1952), Nicolas de Staël (1914-1955)</figcaption>
          </figure>
        </div>

        <p>The palette for Series One calls on Nicolas De Staël’s masterpiece, <em>Parc des Princes</em> (1952), shown in his recent retrospective at the Musée d'Art Moderne de Paris, in which De Staël lyrically suggests the vivid atmosphere of a sports match, portraying the “soaring bodies and bright stadium” with nods to compositions by Old Masters such as Uccello. We weave the painting into Hanuman’s design as an inherently avant-garde exercise, a burst of the modern in dialogue with the art of the past.</p>

        <div className='mGrid'>
          <div className='mGrid-item'>
            <img src='../../m1.png' />
          </div>
          <div className='mGrid-item'>
            <img src='../../m2.png' />
          </div>
          <div className='mGrid-item'>
            <img src='../../m3.png' />
          </div>
          <div className='mGrid-item'>
            <img src='../../m4.png' />
          </div>
          <div className='mGrid-item'>
            <img src='../../m5.png' />
          </div>
          <div className='mGrid-item'>
            <img src='../../m6.png' />
          </div>




        </div>
        <p>For Series Two, we have been turning to the work of the Russian artist Kazimir Malevich, in particular, his geometric costumes and early Suprematist set design for 1913 opera “Victory over the Sun,” as a touchstone of avant-garde enchantment in today’s supersaturated digital context.  With its libretto composed in the Futurist language of “zaum” and raucous musical score, the collaborative production, prefiguring the radical composition of Malevich’s <em>Black Square</em> (1915), celebrated revolutionary new machinic technologies and featured flamboyantly outfitted characters. In our second series, his absurdist characters become book covers, conjuring architectonic reinventions of the human in a provocation for the present.</p>
      </div>

    </div>
  )
}